import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    ContentChildren,
    Directive,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Signal,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    effect,
    signal,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { ContextMenuItem, ContextMenuItemClickData } from '@em4cloud/my-cdk';
import { Tree, TreeFlatNode, TreeNode } from '@em4cloud/tree-view';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { TableConfig } from './table.types';

/**
 * Definition of the table columns
 */
export interface TableColumnDef {
    /**
     * Name of the column displayed
     */
    columnName: string;
    /**
     * Key of the property displayed
     */
    columnKey: string;
    /**
     * Optional property. If true, sorting of the column is enabled
     */
    enableSorting?: boolean;
    /**
     * Optional property. Type of the content in the cell.
     *
     * Can be either int, uuid, text, timestamp, float or decimal.
     */
    contentType?: 'int' | 'uuid' | 'text' | 'timestamp' | 'float' | 'decimal' | string;
    /**
     * Optional property, if not set the column is visible by default.
     */
    visible?: boolean;
    isSelectionColumn?: boolean;
    selectionProperty?: string;
    multiple?: boolean;
}

@Directive({
    selector: '[tableColumnCellContent]',
    standalone: true,
})
export class TableColumnCellDirective {
    @Input() id: string;
    constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({ template: '' })
export class Table extends Tree implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    /**
     * Emits an event with the content of the row that is clicked.
     */
    @Output() onRowsSelected: EventEmitter<Array<any>> = new EventEmitter();
    /**
     * Emits an event after a data is changed.
     */
    @Output() afterDataChanged: EventEmitter<Array<any>> = new EventEmitter();
    /**
     * Emits after an item in context menu is clicked.
     */
    @Output() contextMenuItemClick = new EventEmitter<ContextMenuItemClickData<any>>();

    @Input() tableConfig: TableConfig;
    @Input() displayedColumns: Array<TableColumnDef>;
    @Input('dataSource') data: MatTableDataSource<any>;
    @Input() enableRowSelection: boolean = false;
    @Input() enableMultipleRowSelection: boolean = false;
    @Input() enablePagination = false;
    @Input() treeView = false;

    @Input() filterValue: Signal<string>;
    @Input() filterPredicate: (data: any, filter: string) => boolean;

    @Input() selectedRows: any[];

    /** Select node from parent component */
    @Input() selectRowIds: Signal<string[]>;

    @Input() selectionValues: any = {};

    override dataSource: Signal<
        MatTableDataSource<any> | MatTreeFlatDataSource<TreeNode, TreeFlatNode>
    > = signal(new MatTableDataSource([]));

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @ContentChildren(TableColumnCellDirective)
    customColumnContent!: QueryList<TableColumnCellDirective>;

    selection = new SelectionModel<any>(this.enableMultipleRowSelection, []);

    // Form
    form = new FormGroup({});
    fields: FormlyFieldConfig[];

    constructor(
        private _liveAnnouncer: LiveAnnouncer,
        private _injector: Injector
    ) {
        super();

        effect(() => {
            if (this.dataSource()) {
                const selectedRows = this.dataSource().data.filter(row =>
                    this.selectedRows?.some(
                        selectedRow => JSON.stringify(selectedRow) === JSON.stringify(row)
                    )
                );

                if (this.enableMultipleRowSelection) {
                    this.selection = new SelectionModel<any>(true, []);
                    this.selection.select(...selectedRows);
                    this.watchRowSelection();
                } else {
                    this.selection.select(selectedRows[0]);
                }
            }
        });
    }

    ngOnInit(): void {
        this.selection = new SelectionModel<any>(this.enableMultipleRowSelection, []);
        this.watchRowSelection();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setDataAndDependencies();
    }

    ngAfterViewInit(): void {
        this.setDataAndDependencies();
        this.watchFilterValue();
    }

    ngOnDestroy(): void {
        this.selectionSubscription.unsubscribe();
    }

    checkIfSelected(row: any): boolean {
        return this.selection.selected.some(
            selectedRow => JSON.stringify(selectedRow) === JSON.stringify(row)
        );
    }

    dropTable(event: CdkDragDrop<any[]>): void {
        const previousIndex = event.previousIndex;
        const currentIndex = event.currentIndex;
        const draggedTask = event.item.data;

        //moveItemInArray(this.dataSource.data, previousIndex, currentIndex);
        //this.dataSource = [...this.dataSource.data];
    }

    /**
     * Sets data and dependencies for table
     */
    setDataAndDependencies(): void {
        if (!this.data || !this.data.data) return;
        this.data.paginator = this.paginator;
        this.data.sort = this.sort;
        if (this.filterPredicate) this.data.filterPredicate = this.filterPredicate;
        this.data.connect().subscribe(value => {
            if (this.treeView) {
                this.dataSource = signal(
                    new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, value)
                );
                this.rebuildTreeForData(this.dataSource().data);
            } else {
                this.dataSource = signal(new MatTableDataSource(value));
            }
        });
    }

    /**
     * Return the cell directive by the column id
     * @param id
     * @returns TableColumnCellDirective
     */
    getCustomColumn(id: string): TableColumnCellDirective {
        return this.customColumnContent.find(content => content.id === id);
    }

    private selectionCol: boolean = false;
    selectionColName: string;
    /**
     * Returns the keys of each columns
     * @returns string[]
     */
    getColumnKeys(): string[] {
        let keys = [];
        // the order of column key insertions will affect the order of columns rendered
        if (this.enableRowSelection && !this.treeView) keys.push('select');
        // if (
        //     this.displayedColumns.length &&
        //     this.displayedColumns.find(column => column.isSelectionColumn)
        // ) {
        //     this.selectionCol = true;
        //     const selectionCol = this.displayedColumns.splice(
        //         this.displayedColumns.findIndex(column => column.isSelectionColumn),
        //         1
        //     );
        //     this.selectionColName = selectionCol[0].columnName;
        // }

        keys = [...keys, ...this.displayedColumns.map(column => column.columnKey)];
        // if (this.selectionCol) {
        //     keys.push('dropdown');
        // }

        return keys;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;

        const numRows = this.data.data.length;
        return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    toggleAllRows(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.data.data);
    }

    /**
     * Toggles the selection of a row
     * @param event
     * @param row
     */
    toggleRow(event: any, row: any, column: any): void {
        if (event?.stopPropagation) event.stopPropagation();
        if (this.selection.isSelected(row)) {
            if (!this.tableConfig || !this.tableConfig.disableRowDeselection) {
                this.selection.toggle(row);
            } else {
                event.source.checked = true;
            }
        } else {
            this.selection.toggle(row);
        }
    }

    private selectionSubscription: Subscription;
    /**
     * Called when the row selection changes
     */
    watchRowSelection(): void {
        if (this.selectionSubscription) this.selectionSubscription.unsubscribe();
        this.selectionSubscription = this.selection.changed.subscribe(data => {
            setTimeout(() => {
                this.onRowsSelected.emit(this.selection.selected);
            });
        });
    }

    /**
     * Watch changes and apply filter value
     */
    watchFilterValue(): void {
        effect(
            () => {
                if (this.filterValue) {
                    this.data.filter = this.filterValue().trim().toLowerCase();
                    // If pagination is enabled
                    if (this.enablePagination && this.data.paginator) {
                        this.data.paginator.firstPage();
                    }
                }
            },
            { injector: this._injector }
        );
    }

    /**
     * Announce the change in sort state for assistive technology.
     */
    announceSortChange(sortState: Sort): void {
        //Internationalize these strings.
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce('Sorting cleared');
        }
    }

    /**
     * Tracks the columnKeys for change detection
     * @param index
     * @param item
     * @returns item.columnKey
     */
    customTrackBy(index: number, item: TableColumnDef): string {
        return item.columnKey;
    }

    getDefaultColumnCellValue(row: any, column: TableColumnDef) {
        return row[column.columnKey] != null && row[column.columnKey] != ''
            ? row[column.columnKey]
            : '-';
    }

    /**
     * Function called when a context menu item is clicked of a row
     * @param node row
     * @param menuItem context menu item object that was clicked
     */
    contextMenuItemClicked(node: any, menuItem: ContextMenuItem<any>) {
        this.contextMenuItemClick.emit({ node, menuItem });
    }

    getLabelForChip(column: TableColumnDef, value: any): string {
        const item = this.selectionValues[column.selectionProperty].find(
            item => item.value === value
        );
        return item ? item.label : '';
    }
}
